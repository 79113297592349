import * as React from "react";
import { HeroTemplate } from "../components/Templates";
import { ProjectOverview } from "../components/ProjectComponents";

const IndexPage = () => {
  return (
    <HeroTemplate>
      <ProjectOverview />
    </HeroTemplate>
  );
};

export default IndexPage;
